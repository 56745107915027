import React, {useState, useEffect} from "react";
import {Button, Checkbox, Confirm, Container, Flag, Form, Icon, Label, Radio, Select, Table} from "semantic-ui-react";
import _ from "lodash";
import CountryCodes from "../../../Utils/CountryCodes";

export default function ConsensoFlightTable(props) {
    const [odtAiringId, setOdtAiringId] = useState("");
    const [productOptions, setProductOptions] = useState([]);
    const [initialProducts, setInitialProducts] = useState([]);
    const [currentProducts, setCurrentProducts] = useState([]);
    const [productsToAdd, setProductsToAdd] = useState([]);
    const [entitlementOptions, setEntitlementOptions] = useState([]);
    const [initialEntitlements, setInitialEntitlements] = useState([]);
    const [currentEntitlements, setCurrentEntitlements] = useState([]);
    const [countryOptions, setCountryOptions] = useState([]);
    const [entitlementsToAdd, setEntitlementsToAdd] = useState([]);
    const [initialAuthn, setInitialAuthn] = useState(false);
    const [currentAuthn, setCurrentAuthn] = useState(false);
    const [initialApplyToAllCountries, setInitialApplyToAllCountries] = useState(false);
    const [currentApplyToAllCountries, setCurrentApplyToAllCountries] = useState(false);
    const [initialSelectedCountries, setInitialSelectedCountries] = useState([]);
    const [currentSelectedCountries, setCurrentSelectedCountries] = useState([]);
    const [initialSelectedCountriesAreAllowed, setInitialSelectedCountriesAreAllowed] = useState(true);
    const [currentSelectedCountriesAreAllowed, setCurrentSelectedCountriesAreAllowed] = useState(true);
    const [selectedCountriesToAdd, setSelectedCountriesToAdd] = useState([]);
    const [start, setStart] = useState("");
    const [end, setEnd] = useState("");
    const [isAuthnUpdated, setIsAuthnUpdated] = useState(false);
    const [areProductsUpdated, setAreProductsUpdated] = useState(false);
    const [areEntitlementsUpdated, setAreEntitlementsUpdated] = useState(false);
    const [isApplyToAllCountriesUpdated, setIsApplyToAllCountriesUpdated] = useState(false);
    const [areSelectedCountriesUpdated, setAreSelectedCountriesUpdated] = useState(false);
    const [isSelectedCountriesAreAllowedUpdated, setIsSelectedCountriesAreAllowedUpdated] = useState(false);
    const [confirmationOpen, setConfirmationOpen] = useState(false);

    useEffect(function initializeRow() {
        console.log("ConsensoFlightRow.initializeRow: initial flight: ", props.initialFlight);
        setOdtAiringId(props.initialFlight.odtAiringId || "");
        setInitialProducts(props.initialFlight.products || []);
        setCurrentProducts(props.initialFlight.products || []);
        setInitialEntitlements(props.initialFlight.entitlements || []);
        setCurrentEntitlements(props.initialFlight.entitlements || []);
        setInitialAuthn(props.initialFlight.authn);
        setCurrentAuthn(props.initialFlight.authn);

        if (props.initialFlight.hasOwnProperty("permissions") && props.initialFlight.permissions.hasOwnProperty("countries")) {
            setInitialSelectedCountriesAreAllowed(props.initialFlight.permissions.allowed !== false);
            setCurrentSelectedCountriesAreAllowed(props.initialFlight.permissions.allowed !== false);
            if (props.initialFlight.permissions.countries === "all") {
                setCurrentSelectedCountries([]);
                setInitialSelectedCountries([]);
                setCurrentApplyToAllCountries(true);
                setInitialApplyToAllCountries(true);
            } else {
                const selectedCountries = [];
                for (const country of props.initialFlight.permissions.countries) {
                    selectedCountries.push(country.toLowerCase());
                }
                setCurrentSelectedCountries(selectedCountries.slice());
                setInitialSelectedCountries(selectedCountries.slice());
                setCurrentApplyToAllCountries(false);
                setInitialApplyToAllCountries(false);
            }
        } else {
            setInitialSelectedCountriesAreAllowed(false);
            setCurrentSelectedCountriesAreAllowed(false);
            setCurrentSelectedCountries([]);
            setInitialSelectedCountries([]);
            setInitialApplyToAllCountries(false);
            setCurrentApplyToAllCountries(false);
        }

        setStart(new Date(props.initialFlight.start).toLocaleString("en-US"));
        setEnd(new Date(props.initialFlight.end).toLocaleString("en-US"));
    }, [JSON.stringify(props.initialFlight)]);

    useEffect(function hasAuthnBeenUpdated() {
        setIsAuthnUpdated(initialAuthn !== currentAuthn);
    }, [initialAuthn, currentAuthn]);

    useEffect(function haveProductsBeenUpdated() {
        setAreProductsUpdated(_.difference(initialProducts, currentProducts).length > 0 || _.difference(currentProducts, initialProducts).length > 0);
    }, [JSON.stringify(initialProducts), JSON.stringify(currentProducts)]);

    useEffect(function haveEntitlementsBeenUpdated() {
        setAreEntitlementsUpdated(_.difference(initialEntitlements, currentEntitlements).length > 0 || _.difference(currentEntitlements, initialEntitlements).length > 0);
    }, [JSON.stringify(initialEntitlements), JSON.stringify(currentEntitlements)]);

    useEffect(function hasIsApplyToAllCountriesUpdated() {
        setIsApplyToAllCountriesUpdated(initialApplyToAllCountries !== currentApplyToAllCountries);
    }, [initialApplyToAllCountries, currentApplyToAllCountries]);

    useEffect(function haveSelectedCountriesBeenUpdated() {
        setAreSelectedCountriesUpdated(_.difference(initialSelectedCountries, currentSelectedCountries).length > 0 || _.difference(currentSelectedCountries, initialSelectedCountries).length > 0);
    }, [JSON.stringify(initialSelectedCountries), JSON.stringify(currentSelectedCountries)]);

    useEffect(function haveSelectedCountriesAreAllowedBeenUpdated() {
        setIsSelectedCountriesAreAllowedUpdated(initialSelectedCountriesAreAllowed !== currentSelectedCountriesAreAllowed);
    }, [initialSelectedCountriesAreAllowed, currentSelectedCountriesAreAllowed]);

    useEffect(() => {
        console.log("ConsensoFlightRow.signalRowUpdated: current products? ", currentProducts);
        console.log("ConsensoFlightRow.signalRowUpdated: current entitlements? ", currentEntitlements);
        console.log("ConsensoFlightRow.signalRowUpdated: current selected countries? ", currentSelectedCountries);
        console.log("ConsensoFlightRow.signalRowUpdated: current apply to all countries? ", currentApplyToAllCountries);
        console.log("ConsensoFlightRow.signalRowUpdated: current selected countries are allowed? ", currentSelectedCountriesAreAllowed);
        console.log("ConsensoFlightRow.signalRowUpdated: authn? ", currentAuthn);
    }, [JSON.stringify(currentProducts), JSON.stringify(currentEntitlements), JSON.stringify(currentSelectedCountries), currentApplyToAllCountries, currentAuthn, currentSelectedCountriesAreAllowed]);

    useEffect(function disableApplyToAllCountriesIfCountrySelected() {
        if (currentApplyToAllCountries) {
            setCurrentSelectedCountries([]);
            setSelectedCountriesToAdd([]);
        }
    }, [currentApplyToAllCountries]);

    useEffect(function setApplyToAllCountriesToFalseOnCountrySelection() {
        if (currentSelectedCountries.length > 0 && currentApplyToAllCountries) {
            setCurrentApplyToAllCountries(false);
        }
    }, [JSON.stringify(currentSelectedCountries)]);

    useEffect(function updateProductOptions() {
        const updatedProductUptions = [];
        for (const productOption of props.productOptions) {
            if (!currentProducts.includes(productOption.value)) {
                updatedProductUptions.push(productOption);
            }
        }

        setProductOptions(updatedProductUptions);
    }, [JSON.stringify(props.productOptions), JSON.stringify(currentProducts)]);

    useEffect(function updateEntitlementOptions() {
        console.log("ConsensoFlightRow.brandProductsAndEntitlements", props.brandProductsAndEntitlements);
        const allEntitlements = new Set();
        for (const product of currentProducts) {
            if (props.brandProductsAndEntitlements.hasOwnProperty(product)) {
                for (const entitlement of props.brandProductsAndEntitlements[product]) {
                    if (!currentEntitlements.includes(entitlement)) {
                        allEntitlements.add(entitlement);
                    }
                }
            }
        }

        const updatedEntitlementOptions = [];
        for (const entitlement of Array.from(allEntitlements)) {
            updatedEntitlementOptions.push({key: entitlement, text: entitlement, value: entitlement});
        }
        setEntitlementOptions(updatedEntitlementOptions);
    }, [JSON.stringify(props.brandProductsAndEntitlements), JSON.stringify(currentProducts)]);

    useEffect(function updateSelectedCountriesOptions() {
        const updatedSelectedCountriesOptions = [];
        for (const code of Object.keys(CountryCodes)) {
            if (!currentSelectedCountries.includes(code)) {
                updatedSelectedCountriesOptions.push({key: code, text: `[${code}] ${CountryCodes[code]}`, value: code, flag: code});
            }
        }

        setCountryOptions(updatedSelectedCountriesOptions);
    }, [JSON.stringify(currentSelectedCountries)]);

    useEffect(function signalRowUpdated() {
        const flight = Object.assign({}, props.currentFlight, {
            products: currentProducts.slice(),
            entitlements: currentEntitlements.slice(),
            permissions: {
                countries: currentApplyToAllCountries ? "all" : currentSelectedCountries,
                allowed: currentSelectedCountriesAreAllowed
            },
            authn: currentAuthn
        });

        props.updateFlight(flight, props.index);
    }, [currentAuthn, JSON.stringify(currentEntitlements), JSON.stringify(currentProducts), JSON.stringify(currentSelectedCountries), currentApplyToAllCountries, currentSelectedCountriesAreAllowed]);

    const handleConfirm = () => {
        setCurrentApplyToAllCountries(true);
        setConfirmationOpen(false);
    }

    return (
        <Container>
            <Confirm
                open={confirmationOpen}
                onCancel={() => setConfirmationOpen(false)}
                onConfirm={handleConfirm}
                content="Are you sure you want to apply this to all countries? By confirming, you will lose the list of countries this applies to."
            />
            <Table compact celled verticalAlign="top">
                <Table.Body>
                    <Table.Row>
                        <Table.Cell textAlign="right" width={4}><b>ODT Airing ID</b></Table.Cell>
                        <Table.Cell>{odtAiringId}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell textAlign="right"><b>Flight Window Start</b></Table.Cell>
                        <Table.Cell>{start}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell textAlign="right"><b>Flight Window End</b></Table.Cell>
                        <Table.Cell>{end}</Table.Cell>
                    </Table.Row>
                    <Table.Row style={{background: areProductsUpdated ? "lightblue" : "rgba(0, 0, 0, 0.00)"}}>
                        <Table.Cell textAlign="right"><b>Products</b></Table.Cell>
                        <Table.Cell verticalAlign="top">
                            <Form>
                                {
                                    props.readOnly ? "" :
                                        <Form.Group widths="equal">
                                            <Form.Field
                                                fluid
                                                control={Select}
                                                value={productsToAdd}
                                                options={productOptions}
                                                onChange={(event, {value}) => setProductsToAdd(value)}
                                                allowAdditions
                                                onAddItem={(event, {value}) => props.setProductOptions(productOptions.concat({key: value, text: value, value}))}
                                                search
                                                multiple
                                            />
                                            <Form.Field>
                                                <Button
                                                    fluid
                                                    icon
                                                    color="green"
                                                    onClick={
                                                        () => {
                                                            if (!props.readOnly) {
                                                                const updatedProducts = currentProducts.slice().concat(productsToAdd);
                                                                setCurrentProducts(updatedProducts);
                                                                setProductsToAdd([]);
                                                            }
                                                        }
                                                    }
                                                ><Icon name="plus"/> Add Products</Button>
                                            </Form.Field>
                                        </Form.Group>
                                }
                                {
                                    currentProducts.map((product, index) => {
                                        return (
                                            <Label
                                                as="a"
                                                circular
                                                key={`product-${index}`}
                                                onClick={
                                                    () => {
                                                        if (!props.readOnly) {
                                                            const updatedCurrentProducts = currentProducts.slice();
                                                            updatedCurrentProducts.splice(index, 1);
                                                            setCurrentProducts(updatedCurrentProducts);
                                                        }
                                                    }
                                                }
                                            >
                                                {product}
                                                <Icon name="delete"/>
                                            </Label>
                                        );
                                    })
                                }
                            </Form>
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row style={{background: areEntitlementsUpdated ? "lightblue" : "rgba(0, 0, 0, 0.00)"}}>
                        <Table.Cell textAlign="right"><b>Entitlements</b></Table.Cell>
                        <Table.Cell verticalAlign="top">
                            <Form>
                                {
                                    props.readOnly ? "" :
                                        <Form.Group widths="equal">
                                            <Form.Field
                                                fluid
                                                control={Select}
                                                value={entitlementsToAdd}
                                                options={entitlementOptions}
                                                onChange={(event, {value}) => setEntitlementsToAdd(value)}
                                                onAddItem={(event, {value}) => setEntitlementOptions(entitlementOptions.concat({key: value, text: value, value}))}
                                                allowAdditions
                                                search
                                                multiple
                                            />
                                            <Form.Field>
                                                <Button
                                                    icon
                                                    color="green"
                                                    fluid
                                                    onClick={
                                                        () => {
                                                            const updatedEntitlements = currentEntitlements.slice().concat(entitlementsToAdd);
                                                            setCurrentEntitlements(updatedEntitlements);
                                                            setEntitlementsToAdd([]);
                                                        }
                                                    }
                                                ><Icon name="plus"/> Add Entitlements</Button>
                                            </Form.Field>
                                        </Form.Group>
                                }
                                {
                                    currentEntitlements.map((entitlement, index) => {
                                        return (
                                            <Label
                                                as="a"
                                                circular
                                                key={`entitlement-${index}`}
                                                onClick={
                                                    () => {
                                                        if (!props.readOnly) {
                                                            const updatedCurrentEntitlements = currentEntitlements.slice();
                                                            updatedCurrentEntitlements.splice(index, 1);
                                                            setCurrentEntitlements(updatedCurrentEntitlements);
                                                        }
                                                    }
                                                }
                                            >
                                                {entitlement}
                                                <Icon name="delete"/>
                                            </Label>
                                        );
                                    })
                                }
                            </Form>
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row style={{background: isAuthnUpdated ? "lightblue" : "rgba(0, 0, 0, 0.00)"}}>
                        <Table.Cell textAlign="right"><b>AuthN</b></Table.Cell>
                        <Table.Cell>
                            <Radio
                                toggle
                                checked={currentAuthn === true}
                                disabled={props.readOnly}
                                readOnly={props.readOnly}
                                onClick={() => {
                                    if (!props.readOnly) {
                                        setCurrentAuthn(!currentAuthn);
                                    }
                                }}
                            />
                        </Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
            <Table>
                <Table.Header fullWidth>
                    <Table.HeaderCell colSpan={3}>Geolocation Permissions</Table.HeaderCell>
                </Table.Header>
                <Table.Body>
                    <Table.Row style={{background: isApplyToAllCountriesUpdated ? "lightblue" : "rgba(0, 0, 0, 0.00)"}}>
                        <Table.Cell textAlign="right"><b>Apply to All Countries</b></Table.Cell>
                        <Table.Cell>
                            <Checkbox
                                toggle
                                checked={currentApplyToAllCountries === true}
                                onChange={
                                    () => {
                                        if (!props.readOnly) {
                                            if (!currentApplyToAllCountries && currentSelectedCountries.length > 0) {
                                                setConfirmationOpen(true);
                                            } else {
                                                setCurrentApplyToAllCountries(!currentApplyToAllCountries);
                                            }
                                        }
                                    }
                                }
                                readOnly={props.readOnly}
                            />
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row style={{background: areSelectedCountriesUpdated ? "lightblue" : "rgba(0, 0, 0, 0.00)"}}>
                        <Table.Cell textAlign="right"><b>Selected Countries</b></Table.Cell>
                        <Table.Cell>
                            <Form>
                                {
                                    props.readOnly ? "" :
                                        <Form.Group widths="equal">
                                            <Form.Field
                                                fluid
                                                control={Select}
                                                value={selectedCountriesToAdd}
                                                options={countryOptions}
                                                onChange={(event, {value}) => setSelectedCountriesToAdd(value)}
                                                onAddItem={(event, {value}) => setCountryOptions(countryOptions.concat({key: value, text: value, value}))}
                                                allowAdditions
                                                search
                                                multiple
                                            />
                                            <Form.Field>
                                                <Button
                                                    icon
                                                    color="green"
                                                    fluid
                                                    onClick={
                                                        () => {
                                                            if (!props.readOnly) {
                                                                const updatedSelectedCountries = currentSelectedCountries.slice().concat(selectedCountriesToAdd);
                                                                setCurrentSelectedCountries(updatedSelectedCountries);
                                                                setSelectedCountriesToAdd([]);
                                                            }
                                                        }
                                                    }
                                                ><Icon name="plus"/> Add to Selected Countries</Button>
                                            </Form.Field>
                                        </Form.Group>
                                }
                                {
                                    currentSelectedCountries.map((country, index) => {
                                        return (
                                            <Label
                                                as="a"
                                                circular
                                                key={`selected-country-${country}`}
                                                onClick={() => {
                                                    let updatedCountries = currentSelectedCountries.slice();
                                                    updatedCountries.splice(index, 1);
                                                    setCurrentSelectedCountries(updatedCountries);
                                                }}
                                            >
                                                <Flag name={country} />
                                                <pre>[{country}]</pre> {CountryCodes[country]}
                                                <Icon name="delete" />
                                            </Label>
                                        );
                                    })
                                }
                            </Form>
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row style={{background: isSelectedCountriesAreAllowedUpdated ? "lightblue" : "rgba(0, 0, 0, 0.00)"}}>
                        <Table.Cell textAlign="right"><b>Allow Selected Countries</b></Table.Cell>
                        <Table.Cell>
                            <Checkbox
                                toggle
                                checked={currentSelectedCountriesAreAllowed === true}
                                onChange={() => {
                                    if (!props.readOnly) {
                                        setCurrentSelectedCountriesAreAllowed(!currentSelectedCountriesAreAllowed);
                                    }
                                }}
                            />
                        </Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
        </Container>
    );
};
