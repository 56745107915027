import React, { useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import SecureTabPane from "../../SecureTabPane";
import CreditNotesHandler from "./CreditNotesHandler";

export default function CreditNotesAdminMasterDetailView(props) {
    const [userCanEdit, setUserCanEdit] = useState(false);

    useEffect(function updateUserCanEdit() {
        let updatedUserCanEdit = props.user.authz.includes("urn:all:aspen-power");

        if (!updatedUserCanEdit) {
            for (const permission of props.permissions) {
                const serviceMatches = permission.service === props.service;
                const moduleMatches = ["all", permission.module].includes(permission.module);
                const roleMatches = ["editor", "admin"].includes(permission.role);

                console.log(`Notas De Credito update permissions. Service matches: ${serviceMatches}, module matches ${moduleMatches}, role matches ${roleMatches}`);

                if (serviceMatches && moduleMatches && roleMatches) {
                    updatedUserCanEdit = true;
                    break;
                }
            }
        }
        setUserCanEdit(updatedUserCanEdit);
    }, [JSON.stringify(props.permissions), JSON.stringify(props.user)]);

    return (
        <Grid>
            <Grid.Column width={16} className="masterContainer">
                <SecureTabPane
                    user={props.user}
                    permissionsRequired={props.permissionsRequired}
                    authenticated={props.authenticated}
                    checkIfAuthorized={props.checkIfAuthorized}
                    permissions={props.permissions}
                    userPermissions={props.userPermissions}
                    loadingPermissions={props.loadingPermissions}
                    service={props.service}
                    module={props.module}
                    component={<CreditNotesHandler
                        toast={props.toast}
                        user={props.user}
                        service={props.service}
                        module={props.module}
                        permissions={props.permissions}
                        userCanEdit={userCanEdit} />}
                />
            </Grid.Column>
        </Grid>
    )
}