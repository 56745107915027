import React, {useState, useEffect} from "react";
import {Segment, Grid, Tab, Button} from "semantic-ui-react";
import jwt from "jsonwebtoken";

export default function ConsensoAuditSubcomponent(props) {
    const [showMessage, setShowMessage] = useState(false);
    const [showPayload, setShowPayload] = useState(false);
    const [showAsset, setShowAsset] = useState(false);
    const [message, setMessage] = useState("");
    const [payload, setPayload] = useState("");
    const [asset, setAsset] = useState("")

    useEffect(function decodeMessage() {
        console.log("message: ", props.message);
        if (props.message) {
            setMessage(atob(props.message));
            setShowMessage(true);
        } else {
            setMessage("");
            setShowMessage(false);
        }
    }, [props.message]);

    useEffect(function decodePayload() {
        console.log("payload: ", props.payload);
        if (props.payload) {
            const decodedPayload = atob(props.payload);
            console.log("decoded payload: ", decodedPayload);
            let payloadObject;
            try {
                payloadObject = JSON.parse(decodedPayload);
            } catch (e) {
                payloadObject = jwt.decode(decodedPayload, {complete: true});
            }
            const reencodedPayload = JSON.stringify(payloadObject, null, 4);
            setPayload(reencodedPayload);
            setShowPayload(true);
        } else {
            setPayload("");
            setShowPayload(false);
        }
    }, [props.payload]);

    useEffect(function decodeAsset() {
        console.log("asset: ", props.asset);
        if (props.asset) {
            const reencodedAsset = JSON.stringify(props.asset, null, 4);
            setAsset(reencodedAsset);
            setShowAsset(true);
        } else {
            setAsset("");
            setShowAsset(false);
        }
    }, [props.asset]);

    const copyToClipboard = (payloadType, payload) => {
        navigator.clipboard.writeText(payload).then(() => {
            props.toast("Copied", `You have copied the ${payloadType} to your clipboard.`, "success");
        });
    }

    return (
        <Grid>
            <Grid.Column width={16}>
                <Tab
                    fluid
                    styled
                    defaultActiveIndex={[0]}
                    exclusive={false}
                    panes={[
                        showMessage && {
                            menuItem: "Message",
                            render: () => (
                                <Segment>
                                    <Button floated="right" onClick={() => copyToClipboard("Message", message)}>Copy
                                        Message</Button>
                                    <br/>
                                    <br/>
                                    <pre>{message}</pre>
                                </Segment>
                            )
                        },
                        showPayload && {
                            menuItem: "Payload",
                            render: () => (
                                <Segment>
                                    <Button floated="right" onClick={() => copyToClipboard("Payload", payload)}>Copy
                                        Payload</Button>
                                    <br/>
                                    <br/>
                                    <pre>{payload}</pre>
                                </Segment>
                            )
                        },
                        showAsset && {
                            menuItem: "Asset",
                            render: () => (
                                <Segment>
                                    <Button floated="right" onClick={() => copyToClipboard("Asset", asset)}>Copy
                                        Asset</Button>
                                    <br/>
                                    <br/>
                                    <pre>{asset}</pre>
                                </Segment>
                            )
                        }
                    ]}
                />
            </Grid.Column>
        </Grid>
    );
};
