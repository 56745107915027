import React, {useState, useEffect} from "react";
import CreditNotesDataProvider from "../../../Services/CreditNotesDataProvider";
import {Button, Form, Grid, Message, Input, Loader, Icon, Container} from "semantic-ui-react";
import ReactTable from "react-table-v6";
// import DatePicker from "react-datepicker";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import "react-datepicker/dist/react-datepicker.css";
import DateFnsUtils from "@date-io/date-fns";


export default function CreditNotesHandler(props) {
    const [creditNotes, setCreditNotes] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [enableSearch, setEnableSearch] = useState(true);
    const [batchSize, setBatchSize] = useState("");
    const [totalNewCreditNotes, setTotalNewCreditNotes] = useState(0);
    const [generating, setGenerating] = useState(false);
    const [searching, setSearching] = useState(false);
    const [newCreditNotesCount, setNewCreditNotesCount] = useState(0);
    const [processedCreditNotesCount, setProcessedCreditNotesCount] = useState(0);
    const [failedCreditNotesCount, setFailedCreditNotesCount] = useState(0);
    const CreditNoteStatus = {
        New: "New",
        Processed: "Processed",
        Failed: "Failed"
    }

    CreditNotesDataProvider.init(process.env.CREDIT_NOTES_URL);
    
    useEffect(()=> {
        getAllCreditNotes();
    }, [])
    
    useEffect(() => {
        if(startDate) {
            setEnableSearch(false);
        } else {
            setEnableSearch(true);
        }
    })

    const getAllCreditNotes = () => {
        setSearching(true);
        
        CreditNotesDataProvider.getCreditNotes(null, null).then(resp => {
            if(resp !== null) {
                setCreditNotes(resp);
                setTotalNewCreditNotes(resp.length);
                updateCreditNoteCounts(resp);
            } else {
                setCreditNotes([]);
                setTotalNewCreditNotes(0);
            } 
        }).finally(() => {
            setSearching(false);
        });
    }

    const getCreditNotesByRange = () => {
        setSearching(true);

        const test = new Date(startDate).setHours()

        let start = new Date(startDate).getTime();
        let end;

        if(endDate) {
            end = new Date(endDate).getTime();
        } else {
            end = new Date().getTime();
        }

        CreditNotesDataProvider.getCreditNotes(start, end).then(resp => {
            if(resp !== null) {
                setCreditNotes(resp);
                setTotalNewCreditNotes(resp.length);
                updateCreditNoteCounts(resp);
            } else {
                setCreditNotes([]);
                setTotalNewCreditNotes(0);
            } 
        }).finally(() => {
            setSearching(false);
        });
    }

    const handleGenerateCreditNotes = () => {
        setGenerating(true)
        if(batchSize) {
            let batch = parseInt(batchSize);
            
            if(!isNaN(batch)) {
                CreditNotesDataProvider.generateCreditNotes(batch).then(resp => {
                    setGenerating(false);
                    setBatchSize("");
                    getAllCreditNotes();
                })
                .finally(() => {
                    // we don't know if it's successful or not. That's not under our control.
                    props.toast("Complete", "Credit notes have been submitted");
                });
            }
        }
    }

    const downloadCSV = () => {
        let csvContent = generateCSV();
        const blob = new Blob([csvContent], {type: "text/csv"});
        const a = document.createElement("a");

        a.download = "creditNotes-" + generateTimestamp() + ".csv";
        a.href = window.URL.createObjectURL(blob);
        a.click();
    };

    const generateCSV = () => {
        let headers = ["Refund ID, Refund Date, Amount, Currency, Status, Submitted Date, SAP ID"];
        let creditNoteRows = creditNotes.reduce((acc, creditNotes) => {
            const { refundTransactionId, transactionDate, transactionAmount, currency, status, processedDate, sapTransactionId } = creditNotes;
            acc.push([refundTransactionId, new Date(transactionDate).toLocaleDateString('es-CL'), transactionAmount, currency, status, processedDate, sapTransactionId].join(','));
            return acc
        }, [])
        return [...headers, ...creditNoteRows].join("\n");
    };

    const generateTimestamp = () => {
        let today = new Date();
        let date = today.getDate().toString() + (today.getMonth()+1).toString() + today.getFullYear().toString();
        let time = today.getHours().toString() + today.getMinutes().toString() + today.getSeconds().toString();

        return  date + 'T' + time;
    }

    const updateCreditNoteCounts = (cn) => {
        let newCreditNotes = 0;
        let processedCreditNotes = 0;
        let failedCreditNotes = 0;

        cn.forEach(creditNote => {
            if(creditNote.status === CreditNoteStatus.New) { newCreditNotes++; }
            if(creditNote.status === CreditNoteStatus.Processed) { processedCreditNotes++; }
            if(creditNote.status === CreditNoteStatus.Failed) { failedCreditNotes++; }
        })

        setNewCreditNotesCount(newCreditNotes);
        setProcessedCreditNotesCount(processedCreditNotes);
        setFailedCreditNotesCount(failedCreditNotes);
    }

    return(<Grid>
        <Grid.Column width={10} className="tableContainer">
            <Grid.Column>
                <Message>
                    <Message.Header>Credit Notes Search Result Info</Message.Header>
                    <p>Total credit notes from search was {totalNewCreditNotes}.</p>
                    <p>{newCreditNotesCount} new credit notes to process. {processedCreditNotesCount} credits notes have been submitted. {failedCreditNotesCount} credit notes failed.</p>
                </Message>
                <Form>
                    <Form.Group widths="equal">
                        <Form.Field>
                            <Button onClick={getAllCreditNotes} floated="left">Show New</Button>
                        </Form.Field>
                        <Form.Field>
                            <Button color="gray" onClick={downloadCSV} icon floated="right"><Icon name="download" />Export Credit Notes to CSV</Button>
                        </Form.Field>
                    </Form.Group>
                </Form>

                <p style={{"fontSize":"2vh"}}> *Dates are shown in dd-MM-YYYY format</p>
                <ReactTable
                    className="-striped -highlight"
                    data={creditNotes}
                    loading={searching}
                    columns={[
                        {Header: "Refund ID", accessor: "refundTransactionId", filterable: true},
                        {Header: "Refund Date", accessor: "transactionDate", Cell: (cell) => {
                            return new Date(cell.value).toLocaleDateString('es-CL');
                        }},
                        {Header: "Amount", accessor: "transactionAmount"},
                        {Header: "Currency", accessor: "currency"},
                        {id: "status", Header: "Status", accessor: (row) => {
                            if(row.status === "Processed") {
                                return "Submitted";
                            } else {
                                return row.status;
                            }
                        }, filterable: true},
                        {Header: "Submitted Date", accessor: "processedDate"},
                        {Header: "SAP ID", accessor: "sapTransactionId"}
                    ]}
                />
            </Grid.Column>
        </Grid.Column>
        <Grid.Column width={6} className="EditorContainer">
                <Form>
                <Message>
                    <Message.Header>Refund Time Range Search</Message.Header>
                    <p> Select a start time below. If an end date is not selected, the current time will be used.</p>
                </Message>
                <Form.Group>
                    <Form.Field>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <label>Start Date</label>
                            <DatePicker
                                inputVariant="outlined"
                                fullWidth
                                id="start"
                                value={startDate}
                                onChange={date => {
                                    if (date) {
                                        date.setHours(0);
                                        date.setMinutes(0);
                                        date.setSeconds(0);
                                    }

                                    setStartDate(date);
                                }}
                                format="dd-MM-yyyy"
                                placeholder="Start(DD-MM-YYYY)"
                            />
                        </MuiPickersUtilsProvider>
                    </Form.Field>
                    <Form.Field>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <label>End Date</label>
                            <DatePicker
                                inputVariant="outlined"
                                fullWidth
                                id="start"
                                value={endDate}
                                onChange={date => {
                                    if (date) {
                                        date.setHours(0);
                                        date.setMinutes(0);
                                        date.setSeconds(0);
                                    }

                                    setEndDate(date);
                                }}
                                format="dd-MM-yyyy"
                                placeholder="End(DD-MM-YYYY)"
                            />
                        </MuiPickersUtilsProvider>
                    </Form.Field>
                    <Form.Field>
                        <label>&nbsp;</label>
                        <Button onClick={getCreditNotesByRange} disabled={enableSearch}>Search</Button>
                    </Form.Field>
                </Form.Group>
                <Message>
                    <Message.Header>Generate Credit Notes</Message.Header>
                    <p> Enter the number of credit notes you'd like to process. Note that the oldest will <b>always</b> be processed regardless of filtering </p>
                </Message>
                <Form.Group>
                    <Form.Field
                        control={Input}
                        value={batchSize}
                        onChange={(event, {value}) => setBatchSize(value)}
                    />
                    {
                        !generating ? <Button onClick={handleGenerateCreditNotes}>Submit</Button> :
                        <Button loading disabled>Generating</Button>
                    }
                </Form.Group>
                </Form>
        </Grid.Column>
    </Grid>)
}