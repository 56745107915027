import React, {useState, useEffect} from "react";
import {Button, Form, Grid, Input, Select} from "semantic-ui-react";
import ReactTable from "react-table-v6";
import AdmiralDataProvider from "../../../Services/AdmiralDataProvider";
import ARADataProvider from "../../../Services/ARADataProvider";
import ConsensoDataProvider from "../../../Services/ConsensoDataProvider";
import {DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ConsensoAuditSubcomponent from "./ConsensoAuditSubcomponent";
import OriginCDNMapperDataProvider from "../../../Services/OriginCDNMapperDataProvider";
import AuthDataProvider from "../../../Services/AuthDataProvider";

AdmiralDataProvider.init({baseURL: process.env.ADMIRAL_URL});
ARADataProvider.init({baseURL: process.env.PROD_ARA});
ConsensoDataProvider.init(process.env.CONSENSO_URL);

const SOURCES = {
    medium: "Medium",
    odt: "ODT",
    admin: "Admin"
}
const SOURCE_OPTIONS = Object.keys(SOURCES).map(source => {
    return {key: source, text: SOURCES[source], value: source};
});

const STATUSES = {
    FAILED: "Failed",
    COMPLETED: "Completed",
    NOT_PROCESSED: "Not Processed",
    WILL_PROCESS: "Will Process"
}

const STATUS_OPTIONS = Object.keys(STATUSES).map(status => {
    return {key: status, text: STATUSES[status], value: status};
});

const DATE_DISPLAY_OPTIONS = {year: "numeric", month: "numeric", day: "numeric", hour: "numeric", minute: "numeric", second: "numeric"};


export default function ConsensoAuditMasterDetailView(props) {
    const [appIdOptions, setAppIdOptions] = useState([]);
    const [brandOptions, setBrandOptions] = useState([]);
    const [accessableBrands, setAccessableBrands] = useState([]);
    const [mediaIdOptions, setMediaIdOptions] = useState([]);
    const [odtAiringIdOptions, setOdtAiringIdOptions] = useState([]);
    const [selectedAppId, setSelectedAppId] = useState("");
    const [selectedBrand, setSelectedBrand] = useState("");
    const [mediaIds, setMediaIds] = useState([]);
    const [odtAiringIds, setOdtAiringIds] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState("");
    const [selectedSource, setSelectedSource] = useState("");
    const [start, setStart] = useState(null);
    const [end, setEnd] = useState(null);
    const [auditData, setAuditData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(function initAppIdsAndBrandOptions() {
        const updatedAppIdOptions = [];
        ARADataProvider.getAll().then(response => {
            for (const appId of response) {
                updatedAppIdOptions.push({key: appId.appId, text: appId.appId, value: appId.appId});
            }
        });
        setAppIdOptions(updatedAppIdOptions);

        let userCanSeeAllBrands = false;
        const updatedAccessableBrands = new Set();

        for (const permission of props.userPermissions) {
            if (permission.urn === "urn:all:aspen-power") {
                userCanSeeAllBrands = true;
                break;
            }

            if (permission.service === props.service && permission.module === props.module) {
                if (permission.scope === "all") {
                    userCanSeeAllBrands = true;
                    break;
                } else {
                    updatedAccessableBrands.add(permission.scope);
                }
            }
        }

        if (userCanSeeAllBrands) {
            getAllBrands();
        } else {
            setAccessableBrands(Array.from(updatedAccessableBrands));
        }
    }, []);

    useEffect(function initialAudit() {
        if (accessableBrands.length > 0) {
            performSearch();
        }
    }, [JSON.stringify(accessableBrands)]);

    const getAllBrands = () => {
        OriginCDNMapperDataProvider.getAll(props.user).then(response => {
            const scrubbedBrands = new Set();
            for (const mapping of response) {
                if (mapping.hasOwnProperty("brand")) {
                    const scrubbedBrand = mapping.brand.toLowerCase().replaceAll(/[^a-z\d]/g, "");
                    scrubbedBrands.add(scrubbedBrand);
                }
            }
            setAccessableBrands(Array.from(scrubbedBrands));
        }).catch(error => {
            console.error("ConsensoAuditMasterDetailView.initAppIdsAndBrandOptions", error);
            setAccessableBrands([]);
        });
    };

    useEffect(function updateBrandOptions() {
        const updatedBrandOptions = [];
        for (const brand of accessableBrands) {
            updatedBrandOptions.push({key: brand, text: brand, value: brand});
        }
        setBrandOptions(updatedBrandOptions);
    }, [JSON.stringify(accessableBrands)]);

    const performSearch = () => {
        let brandsToSearch = [];
        if (selectedBrand.length > 0) {
            brandsToSearch.push(selectedBrand);
        } else {
            brandsToSearch = brandsToSearch.concat(accessableBrands);
        }
        const criteria = {
            ...odtAiringIds.length > 0 && {
                odtAiringId: {
                    matchesAny: true,
                        values: odtAiringIds
                    }
                },
            ...mediaIds.length > 0 && {
                mediaId: {
                    matchesAny: true,
                    values: mediaIds
                }
            },
            ...brandsToSearch.length > 0 && {
                brand: {
                    matchesAny: true,
                    values: brandsToSearch
                }
            },
            ...selectedSource && {source: selectedSource},
            ...selectedAppId && {appId: selectedAppId},
            ...selectedStatus && {status: selectedStatus},
            ...start && {start: Math.floor(start / 1000)},
            ...end && {end: Math.floor(end / 1000)}
        };

        setLoading(true);
        ConsensoDataProvider.audit(criteria).then(response => {
            console.log(response);
            setAuditData(response.results);
        }).catch(error => {
            console.error(error);
        }).finally(() => {
            setLoading(false);
        });
    };

    const clearFilters = () => {
        setSelectedAppId("");
        setSelectedBrand("");
        setMediaIds([]);
        setOdtAiringIds([]);
        setSelectedSource("");
        setSelectedStatus("");
        setStart(null);
        setEnd(null);
    };

    return (
        <Grid className="masterContainer">
            <Grid.Column width={3} className="masterContainer" style={{maxHeight: "93vh", overflowY: "auto", overflowX: "hidden"}}>
                <Form widths={16}>
                    <Form.Field>
                        <Button color="blue" onClick={performSearch} fluid>Search</Button>
                    </Form.Field>
                    <Form.Field>
                        <Form.Field>
                            <Button fluid color="black" onClick={clearFilters}>Clear Filters</Button>
                        </Form.Field>
                    </Form.Field>
                    <Form.Field
                        label="Brand"
                        control={Select}
                        options={brandOptions}
                        value={selectedBrand}
                        search
                        clearable
                        allowAdditions
                        onChange={(event, {value}) => setSelectedBrand(value.toLowerCase().replaceAll(/[^a-z0-9]/g, ""))}
                        onAddItem={(event, {value}) => {
                            const scrubbedBrand = value.toLowerCase().replaceAll(/[^a-z0-9]/g, "");
                            setBrandOptions(brandOptions.slice().concat({key: scrubbedBrand, text: scrubbedBrand, value: scrubbedBrand}));
                        }}
                    />
                    <Form.Field
                        label="ODT Airing IDs"
                        control={Select}
                        value={odtAiringIds}
                        options={odtAiringIdOptions}
                        allowAdditions
                        search
                        multiple
                        onChange={(event, {value}) => setOdtAiringIds(value)}
                        onAddItem={(event, {value}) => setOdtAiringIdOptions(odtAiringIdOptions.concat({key: value, text: value, value}))}
                        clearable
                    />
                    <Form.Field
                        label="Media IDs"
                        control={Select}
                        value={mediaIds}
                        options={mediaIdOptions}
                        allowAdditions
                        search
                        multiple
                        onChange={(event, {value}) => setMediaIds(value)}
                        onAddItem={(event, {value}) => setMediaIdOptions(mediaIdOptions.concat({key: value, text: value, value}))}
                        clearable
                    />
                    <Form.Field
                        label="App ID"
                        control={Select}
                        value={selectedAppId}
                        options={appIdOptions}
                        search
                        clearable
                        onChange={(event, {value}) => setSelectedAppId(value)}
                    />
                    <Form.Field
                        label="Status"
                        control={Select}
                        value={selectedStatus}
                        options={STATUS_OPTIONS}
                        search
                        clearable
                        onChange={(event, {value}) => setSelectedStatus(value)}
                    />
                    <Form.Field
                        label="Source"
                        control={Select}
                        value={selectedSource}
                        options={SOURCE_OPTIONS}
                        search
                        clearable
                        onChange={(event, {value}) => setSelectedSource(value)}
                    />
                    <Form.Field>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <label>Start Time</label>
                            <DateTimePicker
                                inputVariant="outlined"
                                fullWidth
                                id="start"
                                value={start}
                                onChange={setStart}
                            />
                        </MuiPickersUtilsProvider>
                    </Form.Field>
                    <Form.Field width={16}>
                        <label>End Time</label>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DateTimePicker
                                inputVariant="outlined"
                                fullWidth
                                id="end"
                                value={end}
                                onChange={setEnd}
                            />
                        </MuiPickersUtilsProvider>
                    </Form.Field>
                </Form>
            </Grid.Column>
            <Grid.Column width={13} style={{maxHeight: "93vh", overflowY: "auto", overflowX: "hidden"}}>
                <Grid.Row>
                    <ReactTable
                        columns={[
                            {
                                Header: "Action",
                                accessor: "action"
                            },
                            {
                                Header: "Brand",
                                accessor: "brand",
                                width: 140
                            },
                            {
                                Header: "Source",
                                accessor: "source",
                                Cell: row => SOURCES.hasOwnProperty(row.original.source) ? SOURCES[row.original.source] : row.original.source,
                                width: 90
                            },
                            {Header: "App ID", accessor: "appId"},
                            {Header: "Media ID", accessor: "mediaId"},
                            {
                                Header: "Status",
                                accessor: "status",
                                Cell: row => STATUSES.hasOwnProperty(row.original.status) ? STATUSES[row.original.status] : row.original.status
                            },
                            {Header: "Path", accessor: "path"},
                            {
                                Header: "Last Updated",
                                accessor: "lastUpdated",
                                filterable: false,
                                defaultSort:"desc",
                                Cell: row => {
                                    return <span>{new Date(row.original.lastUpdated * 1000).toLocaleString("en-US", DATE_DISPLAY_OPTIONS)}</span>;
                                }
                            }
                        ]}
                        data={auditData}
                        loading={loading}
                        filterable={true}
                        defaultSorted={[
                            {id: "lastUpdated", desc: true}
                        ]}
                        SubComponent={row => <ConsensoAuditSubcomponent id={row.original._id} message={row.original.message} payload={row.original.payload} status={row.original.status} asset={row.original.asset} toast={props.toast} />}
                        defaultPageSize={50}
                        onPageChange={() => {
                            document.getElementsByClassName("rt-thead")[0].scrollIntoView();
                        }}
                    />
                </Grid.Row>
            </Grid.Column>
        </Grid>
    );
};
